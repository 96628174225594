import React from "react";

export const TrafficBeforeAfter = (props) => {
    return (
        <div id="trafficBeforeAfter" className="text-center">
            <div className="container">
                <div>
                    <h2>Before & After</h2>
                </div>
                <div className="image-container">
                    <div className="row">
                        <div className="gsc-result">
                            {/* <div className="col-sm-6 col-md-6 col-lg-6 gsc-before"> */}
                            <div className="gsc-before">
                                <img src="img/google-search-console-result-before.png" alt="Google Search Console results before using Boost Traffic service" />
                            </div>
                            <div className="arrow-container">
                                <img src="img/right-arrow.png"
                                    alt="result after" />
                            </div>
                            {/* <div className="col-sm-6 col-md-6 col-lg-6 gsc-after"> */}
                            <div className="gsc-after">
                                <img src="img/google-search-console-result-after.png"
                                    alt="Google Search Console results after using Boost Traffic service" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="gb-result">
                            <div className="col-sm-6 col-md-6 col-lg-6 gb-before">
                                <img src="img/google-business-result-before.png"
                                    alt="Google Business results before using Boost Traffic service" />
                            </div>
                            <div className="arrow-container">
                                <img src="img/right-arrow.png"
                                    alt="result after" />
                            </div>
                            <div className="col-sm-6 col-md-6col-lg-6 gb-after">
                                <img src="img/google-business-result-after.png"
                                    alt="Google Business results after using Boost Traffic service" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};