import React from "react";

export const TrafficAudience = (props) => {
  return (
    <div id="trafficAudience">
      <div className="container">
        <div className="text-center">
          <h2>Who Needs Website Traffic?</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-6">
                <div className="trafficAudience">
                  {/* <div className="trafficAudience-image">
                    {" "}
                    <img src={d.img} alt="" />{" "}
                  </div> */}
                  <div className="divider"></div>
                  <div className="trafficAudience-content">
                    <div className="trafficAudience-meta">{d.name}</div>
                    <p>{d.text}</p>
                  </div>
                </div>
              </div>
            ))
            : "loading"}
        </div>
        <div className="many-others text-center">
          <p>and numerous others not outlined above.</p>
        </div>
      </div>
    </div>
  );
};