import React, { useState } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";

export const Testimonials = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const data = props.data || []; // Fallback to an empty array if data is undefined

    // Handle next slide
    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === data.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Handle previous slide
    const goToPrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? data.length - 1 : prevIndex - 1
        );
    };

    return (
        <div id="testimonials">
            <div className="container">
                <div className="text-center">
                    <h2>What Our Clients Say</h2>
                </div>
                <div className="carousel-container">
                    <button className="carousel-arrow left-arrow" onClick={goToPrev}>
                        {/* <img
                            src="img/prev-arrow.png"
                            alt="previous slide"
                        /> */}
                        <GrPrevious />
                    </button>

                    <div className="carousel-content">
                        {data.map((item, index) => (
                            <div
                                className={`carousel-item fade-in ${index === currentIndex ? "active" : "inactive"
                                    }`}
                                key={index}
                            >
                                <div className="testimonial-content">
                                    <div className="left-section">
                                        <img
                                            src={item.logo}
                                            alt={`${item.company} logo`}
                                            className="testimonial-logo"
                                        />
                                        <div className="user-meta">
                                            <h3>{item.name}</h3>
                                            <p>{item.company}</p>
                                        </div>
                                    </div>
                                    <div className="right-section">
                                        <p className="message">“{item.message}”</p>
                                        <div className="rating">
                                            {Array(item.star)
                                                .fill()
                                                .map((_, index) => (
                                                    <img
                                                        key={index}
                                                        src="img/star.png"
                                                        alt="Star"
                                                        className="rating-star"
                                                    />
                                                ))}
                                            <span className="rating-value">
                                                {item.rating}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <button className="carousel-arrow right-arrow" onClick={goToNext}>
                        {/* <img
                            src="img/next-arrow.png"
                            alt="next slide"
                        /> */}
                        <GrNext />
                    </button>
                </div>
            </div>
        </div>
    );
};
