import React from "react";

export const Features = (props) => {
    return (
        <div id="features" className="text-center">
            <div className="container">
                <div>
                    <h2>Our Features</h2>
                    <p>
                        Why We're the Best Choice for Traffic & Clicks
                    </p>
                </div>
                <div className="row">
                    <div className="box-row-1">
                        <div className="box box-1">
                            <div className="content-1">
                                <img src="img/arrow-up.png" alt="Upward arrow indicating growth" />
                            </div>
                            <div className="content-2">100%</div>
                            <div className="content-3">Real <span>Clicks</span> Premium <span>IPs</span></div>
                        </div>
                        <div className="box box-2">
                            <div className="content-1">Unlimited Keywords</div>
                        </div>
                        <div className="box box-3">
                            <div className="content-1">Ads Safety</div>
                        </div>
                        <div className="box box-4">
                            <div className="content-1">TRY <span>Before</span> BUY</div>
                        </div>
                        <div className="box box-5">
                            <div className="content-1">Mobile / Desktop</div>
                            <div className="content-2">Targeting</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="box-row-2">
                        <div className="box box-1">
                            <div className="content-1">No Installation Needed</div>
                        </div>
                        <div className="box box-2">
                            <div className="content-1">All Traffic Counterss</div>
                        </div>
                        <div className="box box-3">
                            <div className="content-1">Advanced Bot Filtering</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="box-row-3">
                        <div className="first-col">
                            <div className="first-row">
                                <div className="box box-1">
                                    <div className="content-1">CUSTOMER<br />FOCUSED<br />SUPPORT</div>
                                </div>
                                <div className="box box-2">
                                    <div className="content-1">Google Search Console Traffic</div>
                                </div>
                                <div className="box box-3">
                                    <div className="content-1">Countries & Cities Geolocation</div>
                                </div>
                            </div>
                            <div className="second-row">
                                <div className="box box-1">
                                    <div className="content-1">Market's starting cost per click is <span>$0.49</span>, while ours is <span>$0.30</span>.</div>
                                </div>
                            </div>
                        </div>
                        <div className="second-col">
                            <div className="box box-1">
                                <div className="content-1">Fast & Easy<br /> from <br />Start to Finish</div>
                                {/* <div className="content-1">Sign Up</div>
                                <div className="content-1">with</div>
                                <div className="content-1">Just an Email</div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};