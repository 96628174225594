import React from "react";

export const PaymentMethods = (props) => {
    return (
        <div id="paymentMethods">
            <div className="container">
                <div className="row">
                    <div className="row-container">
                        <div className="col-md-6 content-left">
                            <div className="title-mobile">
                                <h2>Supporting</h2>
                                <h3>Payment Methods In Most Currencies！</h3>
                            </div>
                            <div className="payment-image-container">
                                <img src="img/mobile-payment.png" className="img-responsive" alt="Mobile payment illustration" />
                            </div>
                            <div className="payment-steps-container">
                                <div className="payment-steps">
                                    <h2>Easy Steps to Order</h2>
                                    <ul>
                                        <li><span>01</span>Choose a package</li>
                                        <li><span>02</span>Place order with us</li>
                                        <li><span>03</span>Process in progress</li>
                                        <li><span>04</span>Enjoy the results</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 content-right">
                            <div className="payment-text">
                                <div className="title-desktop">
                                    <h2>Supporting</h2>
                                    <h3>Payment Methods In Most Currencies！</h3>
                                </div>
                                <div className="list-payment">
                                    <ul>
                                        {props.data
                                            ? props.data.map((method, index) => {
                                                const images = Object.values(method);

                                                const rows = [];
                                                for (let i = 0; i < images.length; i += 4) {
                                                    rows.push(images.slice(i, i + 4));
                                                }

                                                return rows.map((row, rowIndex) => (
                                                    <div className="payment-row" key={`${index}-${rowIndex}`}>
                                                        {row.map((image, imageIndex) => (
                                                            <li className="col-3" key={`${rowIndex}-${imageIndex}`}>
                                                                <img
                                                                    src={image}
                                                                    alt={`Payment Method ${image.split('/').pop().split('.')[0]}`}
                                                                />                                                            </li>
                                                        ))}
                                                    </div>
                                                ));
                                            })
                                            : "loading"}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};