import React, { useEffect, useState } from "react";
import "./refund.css";

import { Navigation } from "../../components/Navigation";
import { ButtonGroup } from "../../components/ButtonGroup";
import { Footer } from "../../components/Footer";
import { Copyright } from "../../components/Copyright";

import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import { PAGE_PATHS } from "../../constant/paths";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

function RefundPage() {
    const [activeButton, setActiveButton] = useState("refund");
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
        console.log(`${buttonId} button clicked`);
    };

    const [jsonData, setJsonData] = useState({});
    useEffect(() => {
        setJsonData(JsonData);
    }, []);

    return (
        <div>
            <Navigation />
            <ButtonGroup activeButton={activeButton} onButtonClick={handleButtonClick} />
            <div id="refund" className="text-left">
                <div className="container">
                    <div class="title-section">
                        <h2>Refund and Cancellation</h2>
                        <p>Last updated 29 November 2024</p>
                    </div>
                    <div class="refund-section">
                        <div class="left-section">
                            <h2>Introductory</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                At Boost Traffic (hereafter referred to as "Boost Traffic", "we," "our," or "us") , we are dedicated to providing high-quality SEO and digital marketing services. This Refund and Cancellation Policy outlines your rights and procedures for requesting refunds or cancelling services. By purchasing or using our services, you agree to the terms outlined below.
                            </p>
                        </div>
                    </div>
                    <div class="refund-section">
                        <div class="left-section">
                            <h2>1. Refund Policy</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Due to the nature of our digital services (SEO, digital marketing), payments for services are generally non-refundable once work has commenced. However, we offer refunds in certain cases, as outlined below.
                            </p>

                            <ul>
                                <li>
                                    Unused Credits: All unused credits purchased are eligible for a refund.
                                </li>
                                <li>
                                    Partially Delivered Services: If a service is partially delivered, clients are entitled to a refund for the unused portion of the payment.                                </li>
                                <li>
                                    Service Fee Deduction: Refunds for confirmed and paid services are subject to a 15% service fee to cover administrative costs. Customers will receive a refund of 85% of the total payment after this deduction.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="refund-section">
                        <div class="left-section">
                            <h2>2. Service Refund Conditions</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Daily Services
                            </p>

                            <ul>
                                <li>
                                    Refund requests for daily-billed services must be submitted before the service begins.                                </li>
                                <li>
                                    Due to the immediate execution of daily services, we are unable to fulfill a request for an immediate stop and no refunds will be provided once the service has started.
                                </li>
                            </ul>

                            <p>
                                Monthly Services
                            </p>

                            <ul>
                                <li>
                                    For monthly subscriptions: If the service has been provided partially or fully on a specific day, the refund will only apply to the remaining, unused portion starting from the next day as we are unable to fulfill a request for an immediate stop.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="refund-section">
                        <div class="left-section">
                            <h2>3. Refund Process</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Submission and Review
                            </p>
                            <ul>
                                <li>
                                    Refund requests must be initiated via Boost Traffic's official communication channels only, detailing the nature of the request and payment details.
                                </li>
                                <li>
                                    All refund requests are subject to review and approval by Boost Traffic.
                                </li>
                            </ul>

                            <p>
                                Processing Time
                            </p>
                            <ul>
                                <li>
                                    Refund requests will be reviewed on a case-by-case basis, and we will make reasonable efforts to cease or reverse any services in progress to allow a partial refund if applicable.
                                </li>
                                <li>
                                    Refund requests will be reviewed within 5 business days.
                                </li>
                                <li>
                                    Approved refunds will be processed within 7-14 business days, depending on your bank or payment provider.
                                </li>
                            </ul>

                            <p>
                                Important Notes
                            </p>
                            <ul>
                                <li>
                                    Refunds will be credited only to the original payment method or bank account used for the transaction.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="refund-section">
                        <div class="left-section">
                            <h2>4. Cancellation Policy</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Project Cancellation by Client
                            </p>
                            <ul>
                                <li>
                                    Clients may request to cancel services before the agreed-upon start date. Any advance payments will be refunded after deducting the 15% service fee.
                                </li>
                            </ul>
                            <p>
                                Cancellation During Service
                            </p>
                            <ul>
                                <li>
                                    For ongoing projects, we will make reasonable efforts to halt or revoke any uncompleted work. Refunds will only apply to unused credits or future services not yet delivered.
                                </li>
                                <li>
                                    Boost Traffic reserves the right to decline refund requests if the service is near completion or has been substantially executed.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="refund-section">
                        <div class="left-section">
                            <h2>5. Exceptional Circumstances</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Boost Traffic reserves the right to decline refund requests under the following circumstances:
                            </p>
                            <ul>
                                <li>
                                    Services that are fully executed and delivered as per the client’s request.
                                </li>
                                <li>
                                    Refund requests submitted beyond 14 days of the service commencement date.
                                </li>
                                <li>
                                    Miscommunication or lack of detailed instructions from the client that impacts service delivery.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="refund-section">
                        <div class="left-section">
                            <h2>6. Privacy and Data Protection</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                If you have any questions about our Refund & Cancellation Policy or need to submit a request, please reach out to us:
                            </p>
                            <ul>
                                <li>
                                    <a
                                        href={`mailto:${process.env.REACT_APP_EMAIL_LINK}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Send an email to boosttraffic.official@gmail.com">
                                        Email: boosttraffic.official@gmail.com
                                    </a>                                </li>
                                <li>
                                    <a href="tel:+601125519178"> Phone: +6011-2551 9178</a>
                                </li>
                            </ul>
                            <p>
                                Boost Traffic is committed to maintaining transparency and ensuring customer satisfaction. We encourage clients to contact us directly for any clarifications or concerns.
                            </p>
                        </div>

                    </div>
                    <p>
                        By using our services, you agree to the terms of this Refund & Cancellation Policy.
                    </p>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    );
}

export default RefundPage;

