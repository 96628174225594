import React, { useEffect, useState } from "react";
import "./terms.css";

import { Navigation } from "../../components/Navigation";
import { ButtonGroup } from "../../components/ButtonGroup";
import { Footer } from "../../components/Footer";
import { Copyright } from "../../components/Copyright";
import JsonData from "../../data/data.json";
import { PAGE_PATHS } from "../../constant/paths";

function TermsPage() {
    const [activeButton, setActiveButton] = useState("terms");
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
        console.log(`${buttonId} button clicked`);
    };

    const [jsonData, setJsonData] = useState({});
    useEffect(() => {
        setJsonData(JsonData);
    }, []);

    return (
        <div>
            <Navigation />
            <ButtonGroup activeButton={activeButton} onButtonClick={handleButtonClick} />
            <div id="terms" className="text-left">
                <div className="container">
                    <div class="title-section">
                        <h2>Terms and Conditions</h2>
                        <p>Last updated 29 November 2024</p>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>General Terms</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Welcome to Boost Traffic (hereafter referred to as "Boost Traffic", "we," "our," or "us"). These Terms and Conditions ("Terms") govern your access to and use of our website boosttraffic.official@gmail.com and the services we provide, including transactions initiated through our official communication channels such as WhatsApp, Facebook Messenger, and Instagram Direct. These platforms are used specifically for customer interactions and transactions, and are the only authorized means for conducting business with us.
                            </p>
                            <p>
                                By accessing or using our website, services, or initiating communication or transactions through any of these official platforms, you agree to comply with these Terms. If you do not agree with these Terms, please refrain from using our services or interacting with us through these platforms.
                            </p>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>1. Services</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Boost Traffic provides digital marketing services, including but not limited to Search Engine Optimization (SEO), traffic enhancement, and online ranking services for businesses across various industries. All services are subject to availability and may be subject to change. These services are available through our official platforms.
                            </p>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>2. User Obligations</h2>
                        </div>
                        <div class="right-section">
                            <p>By using our website or services, you agree to:</p>

                            <ul>
                                <li>
                                    Ensure that all transactions and communications are conducted through Boost Traffic’s official platforms, including but not limited to
                                    <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">&nbsp;Whatsapp</a>,
                                    <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">&nbsp;Facebook</a> and
                                    <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">&nbsp;Instagram</a>.
                                    Transactions initiated outside of these platforms will not be considered valid.
                                </li>
                                <li>
                                    Comply with all applicable laws and regulations.
                                </li>
                                <li>
                                    Not engage in any activity that interferes with or disrupts our website or services.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>3. Account and Registration</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                To access certain features of our website or use certain services, you may be required to create an account. You agree to:
                            </p>
                            <ul>
                                <li>
                                    Provide accurate and up-to-date information.
                                </li>
                                <li>
                                    Maintain the confidentiality of your account details and password.
                                </li>
                                <li>
                                    Notify us immediately of any unauthorized use of your account.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>4. Payment Terms</h2>
                        </div>
                        <div class="right-section">
                            <ul>
                                <li>
                                    Services provided by Boost Traffic are paid services. Payment must be made according to the pricing and payment terms provided at the time of purchase.
                                </li>
                                <li>
                                    All payment transactions will be processed through official communication channels (WhatsApp, Facebook, Instagram) and the Boost Traffic official platform you are using to interact with us.
                                </li>
                                <li>
                                    All fees and charges for services must be paid in full before services can be rendered unless otherwise specified in writing.
                                </li>
                                <li>
                                    We reserve the right to change our pricing at any time; however, the new pricing will not affect services already purchased.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>5. Refund Policy</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Refunds are processed according to our <a href={PAGE_PATHS.REFUND_CANCELLATION}>Refund & Cancellation Policy</a>. Refund requests must be initiated through Boost Traffic’s official communication platforms (WhatsApp, Facebook, Instagram) and are subject to the conditions outlined in the Refund Policy.
                            </p>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>6. Content Ownership</h2>
                        </div>
                        <div class="right-section">
                            <ul>
                                <li>
                                    The content you provide to us (such as text, images, logos, or any other intellectual property) remains your property.
                                </li>
                                <li>
                                    By providing content to us, you grant Boost Traffic a non-exclusive, royalty-free license to use, display, modify, and distribute your content solely for the purposes of delivering our services.
                                </li>
                                <li>
                                    All content provided on our website, any Boost Traffic official Third-Party platform, in our communications, or through our services, including but not limited to text, graphics, and logos, is the property of Boost Traffic and protected by copyright laws. Users are not permitted to use, reproduce, or distribute any content without prior written consent.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>7. Privacy and Data Protection</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                We take your privacy seriously. Please refer to our Privacy Policy for detailed information about how we collect, use, and protect your personal data when you use our services or interact with us via our official platforms (WhatsApp, Facebook, Instagram).
                            </p>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>8. Prohibited Activities</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                You agree not to:
                            </p>
                            <ul>
                                <li>
                                    Engage in any fraudulent or unlawful activities.
                                </li>
                                <li>
                                    Attempt to gain unauthorized access to our services or website.
                                </li>
                                <li>
                                    Use our services to promote illegal activities or malicious content.
                                </li>
                                <li>
                                    Violate the intellectual property rights of others.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>9. Limitation of Liability</h2>
                        </div>
                        <div class="right-section">
                            <ul>
                                <li>
                                    Boost Traffic does not guarantee any specific results from the services provided. We will make reasonable efforts to improve SEO and online rankings, but we do not guarantee any specific level of performance.
                                </li>
                                <li>
                                    In no event shall Boost Traffic be liable for any direct, indirect, incidental, special, or consequential damages, including but not limited to, loss of profits, loss of data, or business interruption, arising from your use of our website or services.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>10. Indemnity</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                You agree to indemnify, defend, and hold harmless Boost Traffic and its affiliates, employees, and agents from any claims, damages, liabilities, costs, or expenses (including legal fees) arising out of your use of our services or violation of these Terms.
                            </p>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>11. Termination</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                We reserve the right to suspend or terminate your account and access to our services at our discretion, without prior notice, if you breach any of these Terms.
                            </p>
                        </div>
                    </div>
                    <div class="terms-section">
                        <div class="left-section">
                            <h2>12. Changes to Terms</h2>
                        </div>
                        <div class="right-section">
                            <p>
                                Boost Traffic reserves the right to modify, update, or revise these Terms at any time. Any changes will be posted on this page with an updated effective date. Your continued use of our services after such changes constitutes your acceptance of the modified Terms.
                            </p>
                            <ul class="no-bullets">
                                <li>
                                    <a
                                        href={`mailto:${process.env.REACT_APP_EMAIL_LINK}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="Send an email to boosttraffic.official@gmail.com">
                                        Email: boosttraffic.official@gmail.com
                                    </a>                                </li>
                                <li>
                                    <a href="tel:+601125519178"> Phone: +6011-2551 9178</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    );
}

export default TermsPage;

