import React from "react";

export const Header = (props) => {
    return (
        <header id="header">
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 intro-text">
                                <h1>
                                    Grow Your Brand
                                    <br />
                                    Get <span>High Traffic</span> with Real Clicks
                                </h1>
                                <p>We provide real and highly effective clicks to boost your brand's search ranking.</p>
                            </div>
                        </div>
                        <div className="row">
                            {/* Full-Width Image */}
                            <div className="full-width-image">
                                <img
                                    src="img/google-search-console-traffic.jpg"
                                    alt="Google search console traffic"
                                />
                            </div>

                            {/* Row of 4 Small Icon Images */}
                            <div className="icon-row">
                                <div className="row text-center">
                                    <div className="col-md-3 col-sm-6 d-flex align-items-center icon-container">
                                        <div className="icon-text">
                                            <img
                                                src="img/check-mark.png"
                                                alt="No Installation checkmark"
                                            />
                                            <p>No Installation</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 d-flex align-items-center icon-container">
                                        <div className="icon-text">
                                            <img
                                                src="img/check-mark.png"
                                                alt="Below Market Price checkmark"
                                            />
                                            <p>Below Market Price</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 d-flex align-items-center icon-container">
                                        <div className="icon-text">
                                            <img
                                                src="img/check-mark.png"
                                                alt="Premium IP & Clicks checkmark"
                                            />
                                            <p>Premium IP & Clicks</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 d-flex align-items-center icon-container">
                                        <div className="icon-text">
                                            <img
                                                src="img/check-mark.png"
                                                alt="Same Day Customer Support checkmark"
                                            />
                                            <p>Same Day Customer Support</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
