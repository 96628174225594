import { useState } from "react";
import "./footer.css";
import { PAGE_PATHS } from "../constant/paths"; 

export const Footer = (props) => {


    const scrollToTab = (e) => {
        e.preventDefault();
      
        const currentPath = window.location.pathname;
        const targetId = e.target.getAttribute("href").substring(1);
      
        const restrictedPages = [
          PAGE_PATHS.PRIVACY_POLICY,
          PAGE_PATHS.TERMS,
          PAGE_PATHS.REFUND_CANCELLATION,
        ];
      
        if (restrictedPages.includes(currentPath)) {
          window.location.href = `/#${targetId}`;
        } else {
          // Scroll to the section on the current page
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            window.scrollTo({
              top: targetElement.offsetTop - 50,
              behavior: "smooth",
            });
          }
        }
      };
    

    return (
        <div id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 more-info">
                        <div className="col-md-7 left-section">
                            <h2>Need More Info?</h2>
                            <p>
                                No worries at all! Just drop us a message and we’ll be happy to assist you with any questions or concerns you might have.
                            </p>
                        </div>
                        <div className="col-md-5 right-section">
                            <div className="row">
                                <div className="col-md-12 ws-img-container">
                                    <a href={process.env.REACT_APP_CONTACT_LINK} target="_blank" rel="noopener noreferrer">
                                        <img src="img/ws-icon.png" className="ws-img" alt="Contact us via Whatsapp" />
                                        Whatsapp
                                    </a>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 email-img-container">
                                    <a
                                        href={`mailto:${process.env.REACT_APP_EMAIL_LINK}`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img src="img/email-icon.png" className="email-img" alt="Contact us via Email" />
                                        Email Us
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 social-media">
                        <div className="col-md-4 social-media-section">
                            <div className="boost-traffics">
                                <img src="img/boost-traffics-logo.png" alt="boost trffic logo" />
                            </div>
                            <p>
                                Boost your brand with real clicks and high traffic to improve your search ranking.
                            </p>
                            <div className="social-media-icons">
                                <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">
                                    <img src="img/fb-icon.png" alt="Facebook" className="social-icon" />
                                </a>
                                <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
                                    <img src="img/ig-icon.png" alt="Instagram" className="social-icon" />
                                </a>
                                <a href={process.env.REACT_APP_CONTACT_LINK} target="_blank" rel="noopener noreferrer">
                                    <img src="img/ws-icon-1.png" alt="WhatsApp" className="social-icon" />
                                </a>
                            </div>
                        </div>

                        <div className="col-md-3 about-section">
                            <h5>About</h5>
                            <ul>
                                <li><a href="#websiteTraffic" onClick={scrollToTab}>How it Works</a></li>
                                <li><a href="#trafficComparison" onClick={scrollToTab}>With vs Without </a></li>
                                <li><a href="#pricingTable" onClick={scrollToTab}>Pricing</a></li>
                                <li><a href="#paymentMethods" onClick={scrollToTab}>Payments</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3 more-section">
                            <h5>More</h5>
                            <ul>
                                <li><a href={PAGE_PATHS.TERMS}>Terms</a></li>
                                <li><a href={PAGE_PATHS.PRIVACY_POLICY}>Policies</a></li>
                            </ul>
                        </div>
                        <div className="col-md-2 contact-section">
                            <h5>Contact Us</h5>
                            <a href={process.env.REACT_APP_CONTACT_LINK} className="btn btn-contact" target="_blank">Get in touch</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
