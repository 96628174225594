import { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { PAGE_PATHS } from "../../../constant/paths";

const initialState = {
    name: "",
    email: "",
    message: "",
};

export const Contact = (props) => {

    const scrollToTab = (e) => {
        e.preventDefault();

        const targetId = e.target.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop - 50,
                behavior: 'smooth',
            });
        }
    };

    // State to track which section is open
    const [activeIndex, setActiveIndex] = useState(null);

    // Toggle collapse state
    const toggleCollapse = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const [{ name, email, message }, setState] = useState(initialState);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const clearState = () => setState({ ...initialState });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name || !email || !message) {
            setAlertMessage({ text: "Please fill in all fields.", type: "error" });
            setTimeout(() => {
                setAlertMessage({ text: "", type: "" });
            }, 3000);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/send-email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name, email, message }),
            });

            if (response.ok) {
                const result = await response.json();
                // console.log(result);
                setAlertMessage({ text: "Your email has been sent successfully! We will get back to you shortly.", type: "success" });
                clearState();
            } else {
                const errorResult = await response.json();
                console.error("Failed to send email:", errorResult.error);
                setAlertMessage({ text: "Failed to send email. Please try again.", type: "error" });
                setTimeout(() => {
                    setAlertMessage({ text: "", type: "" });
                }, 3000)
            }
        } catch (error) {
            console.error("Error sending email:", error);
            setAlertMessage({ text: "An error occurred while sending the email. Please try again.", type: "error" });
            setTimeout(() => {
                setAlertMessage({ text: "", type: "" });
            }, 3000)
        }
    };

    useEffect(() => {
        if (alertMessage.text) {
            const timer = setTimeout(() => {
                setAlertMessage({ ...alertMessage, visible: true });
            }, 50);
            return () => clearTimeout(timer);
        }
    }, [alertMessage.text]);

    return (
        <div id="contact">
            <div className="container">
                <div className="row">
                    <div className="content-container">
                        <div className="left-container col-md-6">
                            <div className="contact-title">
                                <h2>
                                    <img src="img/boost-logo.png" alt="Boost Traffics Logo" />
                                    Drop Us a Line!
                                </h2>
                                <p>
                                    Fill out the form, and we’ll get back to you soon.
                                </p>
                            </div>
                            <form name="sentMessage" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        placeholder="Name"
                                        value={name}
                                        onChange={handleChange}
                                    />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={handleChange}
                                    />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="message"
                                        id="message"
                                        className="form-control"
                                        rows="4"
                                        placeholder="Write Message..."
                                        value={message}
                                        onChange={handleChange}
                                    ></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <button type="submit" className="btn btn-custom">
                                    Submit
                                </button>
                                {alertMessage.text && (
                                    <div
                                        className={`mt-3 ${alertMessage.type === "success" ? "text-success" : "text-danger"
                                            } message-visible fade-in`}
                                    >
                                        <p>{alertMessage.text}</p>
                                    </div>
                                )}
                            </form>
                        </div>

                        <div className="right-container col-md-6">
                            <div className="faq-item">
                                {props.data
                                    ? props.data.map((d, i) => (
                                        <React.Fragment key={i}>
                                            <h3>
                                                <button
                                                    className="btn btn-link"
                                                    type="button"
                                                    data-toggle="collapse"
                                                    data-target={`#info-${i}`}
                                                    aria-expanded="false"
                                                    aria-controls={`info-${i}`}
                                                    onClick={() => toggleCollapse(i)}

                                                >
                                                    {d.title}

                                                </button>
                                                <span className="collapse-icon">
                                                    {activeIndex === i ? "-" : "+"}
                                                </span>
                                            </h3>
                                            <div
                                                id={`info-${i}`}
                                                className={`custom-collapse ${activeIndex === i ? "show" : ""}`}                                            >
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: d.content
                                                            .replace(
                                                                "CONTACT_LINK",
                                                                process.env.REACT_APP_CONTACT_LINK
                                                            )
                                                            .replace("REFUND_PAGE",
                                                                PAGE_PATHS.REFUND_CANCELLATION
                                                            ),
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))
                                    : "loading"}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};
