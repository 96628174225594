import React from "react";

export const WebsiteTraffic = (props) => {
    return (
        <div id="websiteTraffic">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6 image-container">
                        <div className="image-section">
                            {/* Yellow Background */}
                            <div className="yellow-background"></div>

                            {/* Card 1: Trusted by Many */}
                            <div className="card card-1 volume-visitiors">
                                <h3>Volume of Visitors</h3>
                                <p>Website traffic measures how many people visit a website over a specific period, reflecting the site's reach and popularity.</p>
                                <div className="icons">
                                    <img src="img/visitor-1.png" alt="Frequent Visitors Icon" />
                                    <img src="img/visitor-2.png" alt="New Visitors Icon" />
                                    <img src="img/visitor-3.png" alt="Returning Visitors Icon" />
                                    <img src="img/visitor-4.png" alt="Unique Visitors Icon" />
                                    <img src="img/visitor-5.png" alt="Engaged Visitors Icon" />
                                    <span className="plus">+</span>
                                </div>
                            </div>

                            {/* Card 2: Statistic Activity */}
                            <div className="card card-2 graph-comparison">
                                <h3>Graph Comparison</h3>
                                <div className="img-container">
                                    <img src="img/graph.png" alt="Comparison graph showing traffic trends" />
                                </div>
                            </div>

                            {/* Card 3: Today Graphic */}
                            <div className="card card-3 search-ranking">
                                <h3>Search Engine Ranking</h3>
                                <img src="img/rank.png" alt="Visual representation of search engine rankings" />
                            </div>

                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 text-container">
                        <div className="website-traffic-text">
                            <h2>What is Website Traffic?</h2>
                            <p>Website traffic refers to the flow of visitors to your site, generated through various channels. Higher traffic means more exposure, helping to boost your brand visibility, increase sales, and improve overall business performance. Real clicks drive organic growth and make your brand stand out.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};