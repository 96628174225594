import { useState } from "react";
import "./copy-right.css";

export const Copyright = (props) => {
    return (
        <div id="copyright">
            <div className="container">
                <div className="footer-text">
                    <p>© 2024 Boost Traffics. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    );
};

