import React, { useState } from "react";

export const PricingTable = () => {
    const [isMonthly, setIsMonthly] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState("advance");

    const togglePricing = (isMonthly) => {
        setIsMonthly(isMonthly);
        setSelectedPlan("advance");
    };

    const handleSelect = (planName) => {
        setSelectedPlan(planName);
    };

    return (
        <div id="pricingTable" className="py-5 bg-light">
            <div className="container">
                <div className="text-center">
                    <h2>Choose Your Plan</h2>
                    <p>No Contracts. No Hidden Fees.</p>
                </div>
                <div className="btn-mobile btn-container mb-5">
                    <button
                        className={`btn-month ${isMonthly ? "active" : ""}`}
                        onClick={() => togglePricing(true)}
                    >
                        Monthly
                    </button>
                    <button
                        className={`btn-day btn-outline-primary ${!isMonthly ? "active" : ""}`}
                        onClick={() => togglePricing(false)}
                    >
                        Daily
                    </button>
                </div>

                <div className="price-table table-responsive">
                    <table className="table text-center align-middle">
                        <thead>
                            {isMonthly ? (
                                <>
                                    <tr>
                                        <th>
                                            <div className="btn-desktop btn-container mb-5">
                                                <button
                                                    className={`btn-month ${isMonthly ? "active" : ""}`}
                                                    onClick={() => togglePricing(true)}
                                                >
                                                    Monthly
                                                </button>
                                                <button
                                                    className={`btn-day btn-outline-primary ${!isMonthly ? "active" : ""}`}
                                                    onClick={() => togglePricing(false)}
                                                >
                                                    Daily
                                                </button>
                                            </div>
                                        </th>
                                        <th className={selectedPlan === "free" ? "pricing-active" : ""}>
                                            <div className="pricing-background">

                                                <div className="pricing-info">Free</div>
                                                <div className="pricing-amount">$0</div>
                                                <div className="pricing-period">Only for New User</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("free")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>
                                        </th>
                                        <th className={selectedPlan === "standard" ? "pricing-active" : ""}>
                                            <div className="pricing-background">

                                                <div className="pricing-info">Standard</div>
                                                <div className="pricing-amount">$252</div>
                                                <div className="pricing-period">Per Month</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("standard")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>
                                        </th>
                                        <th className={selectedPlan === "advance" ? "pricing-active" : ""}>
                                            <div className="pricing-background">
                                                <div className="pricing-info">Advance</div>
                                                <div className="pricing-amount">$700</div>
                                                <div className="pricing-period">Per Month</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("advance")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>

                                        </th>
                                        <th className={selectedPlan === "custom" ? "pricing-active" : ""}>
                                            <div className="pricing-background">

                                                <div className="pricing-info">Custom</div>
                                                <div className="pricing-amount">$</div>
                                                <div className="pricing-period">Per Month</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("custom")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>
                                        </th>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    <tr>
                                        <th>
                                            <div className="btn-desktop btn-container mb-5">
                                                <button
                                                    className={`btn-month ${isMonthly ? "active" : ""}`}
                                                    onClick={() => togglePricing(true)}
                                                >
                                                    Monthly
                                                </button>
                                                <button
                                                    className={`btn-day btn-outline-primary ${!isMonthly ? "active" : ""}`}
                                                    onClick={() => togglePricing(false)}
                                                >
                                                    Daily
                                                </button>
                                            </div>
                                        </th>
                                        <th className={selectedPlan === "free" ? "pricing-active" : ""}>
                                            <div className="pricing-background">

                                                <div className="pricing-info">Free</div>
                                                <div className="pricing-amount">$0</div>
                                                <div className="pricing-period">Only for New User</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("free")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>
                                        </th>
                                        <th className={selectedPlan === "standard" ? "pricing-active" : ""}>
                                            <div className="pricing-background">

                                                <div className="pricing-info">Standard</div>
                                                <div className="pricing-amount">$9</div>
                                                <div className="pricing-period">Per Day</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("standard")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>
                                        </th>
                                        <th className={selectedPlan === "advance" ? "pricing-active" : ""}>
                                            <div className="pricing-background">

                                                <div className="pricing-info">Advance</div>
                                                <div className="pricing-amount">$29</div>
                                                <div className="pricing-period">Per Day</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("advance")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>
                                        </th>
                                        <th className={selectedPlan === "custom" ? "pricing-active" : ""}>
                                            <div className="pricing-background">

                                                <div className="pricing-info">Custom</div>
                                                <div className="pricing-amount">$</div>
                                                <div className="pricing-period">Per Day</div>
                                                <a href={process.env.REACT_APP_CONTACT_LINK}
                                                    onClick={() => handleSelect("custom")}
                                                    target="_blank">
                                                    <button className="pricing-btn">
                                                        Select
                                                    </button>
                                                </a>
                                            </div>
                                        </th>
                                    </tr>
                                </>
                            )}
                        </thead>
                        <tbody>
                            {isMonthly ? (
                                <>
                                    <tr className="content-row">
                                        <td className="content-label">Clicks Delivered</td>
                                        <td className="content-value">5 Clicks</td>
                                        <td className="content-value">
                                            <p>840 Clicks</p>
                                            <small>30 clicks/day</small>
                                        </td>
                                        <td className="content-value">
                                            <p>2,800 Clicks</p>
                                            <small>100 clicks/day</small>
                                        </td>
                                        <td className="content-value">Unlimited</td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Page views</td>
                                        <td className="content-value">15 Views</td>
                                        <td className="content-value">
                                            <p>2,520 Views</p>
                                            <small>At least 90 views/day</small>
                                        </td>
                                        <td className="content-value">
                                            <p>8,400 Views</p>
                                            <small>At least 300 views/day</small>
                                        </td>
                                        <td className="content-value">Unlimited</td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Pages per visit</td>
                                        <td className="content-value">3 Pages</td>
                                        <td className="content-value">3 Pages</td>
                                        <td className="content-value">3 Pages</td>
                                        <td className="content-value">3 Pages</td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Targeted Keyword</td>
                                        <td className="content-value">1 Keyword</td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            /></td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Min. 1 mins Avg. Time on Page</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Countries Geo-Targeting</td>
                                        <td className="content-value"></td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Cities Geo-Targeting</td>
                                        <td className="content-value"></td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Mobile/Desktop Targeting</td>
                                        <td className="content-value"></td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Google Search Console Traffic</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Advanced bot filtering</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Google Search Console Traffic</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    <tr className="content-row">
                                        <td className="content-label">Clicks Delivered</td>
                                        <td className="content-value">5 Clicks</td>
                                        <td className="content-value">30 Clicks</td>
                                        <td className="content-value">100 Clicks</td>
                                        <td className="content-value">Unlimited</td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Page views</td>
                                        <td className="content-value">15 Views</td>
                                        <td className="content-value">90 Views</td>
                                        <td className="content-value">300 Views</td>
                                        <td className="content-value">Unlimited</td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Pages per visit</td>
                                        <td className="content-value">3 Pages</td>
                                        <td className="content-value">3 Pages</td>
                                        <td className="content-value">3 Pages</td>
                                        <td className="content-value">3 Pages</td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Targeted Keyword</td>
                                        <td className="content-value">1 Keyword</td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            /></td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Min. 1 mins Avg. Time on Page</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/infinity.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Countries Geo-Targeting</td>
                                        <td className="content-value"></td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Cities Geo-Targeting</td>
                                        <td className="content-value"></td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Mobile/Desktop Targeting</td>
                                        <td className="content-value"></td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Google Search Console Traffic</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Advanced bot filtering</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    <tr className="content-row">
                                        <td className="content-label">Google Search Console Traffic</td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                        <td className="content-value">
                                            <img
                                                src="img/check-mark-1.png"
                                                alt="" aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="text-contact">
                    <p>
                        Not sure which plan suits you?{" "}
                        <a
                            href="https://www.google.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-link"
                        >
                            Contact us
                        </a>{" "}
                        for a free consultation!
                    </p>
                </div>
            </div>
        </div>
    );
};
