import React, { useEffect, useState } from "react";

import { Navigation } from "../../components/Navigation";
import { Header } from "./components/Header";
import { WebsiteTraffic } from "./components/WebsiteTraffic";
import { TrafficAudience } from "./components/TrafficAudience";
import { TrafficComparison } from "./components/TrafficComparison";
import { TrafficBeforeAfter } from "./components/TrafficBeforeAfter";
import { PricingTable } from "./components/PricingTable";
import { Features } from "./components/Features";
import { PaymentMethods } from "./components/PaymentMethods";
import { Testimonials } from "./components/Testimonials";
import { Contact } from "./components/Contact";
import { Footer } from "../../components/Footer";
import { Copyright } from "../../components/Copyright";

import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

window.onload = () => {
  const scrollToHash = () => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const targetElement = document.getElementById(hash);
      if (targetElement) {
        // console.log(`Scrolling to: ${hash}`);
        window.scrollTo({
          top: targetElement.offsetTop - 50,
          behavior: "smooth",
        });
      } else {
        requestAnimationFrame(scrollToHash);
      }
    }
  };
  scrollToHash();
};

function HomePage() {
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    setJsonData(JsonData); 
  }, []);

  return (
    <div>
      <Navigation />
      <Header />
      <WebsiteTraffic />
      <TrafficAudience data={jsonData.TrafficAudience} />
      <TrafficComparison  data={jsonData.TrafficComparison}/>
      <TrafficBeforeAfter />
      <PricingTable />
      <Features />
      <PaymentMethods data={jsonData.PaymentMethods}/>
      <Testimonials data={jsonData.TestimonialsData}/>
      <Contact data={jsonData.FaqData}/>
      <Footer />
      <Copyright />
    </div>
  );
}

export default HomePage;
