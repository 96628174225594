import React, { useState } from "react";
import "./navigation.css";
import { PAGE_PATHS } from "../constant/paths";

export const Navigation = (props) => {

  const [selectedLanguage, setSelectedLanguage] = useState("EN");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language); // Update the selected language
  };

  const scrollToTab = (e) => {
    e.preventDefault();

    const currentPath = window.location.pathname;
    const targetId = e.target.getAttribute("href").substring(1);

    const restrictedPages = [
      PAGE_PATHS.PRIVACY_POLICY,
      PAGE_PATHS.TERMS,
      PAGE_PATHS.REFUND_CANCELLATION,
    ];

    if (restrictedPages.includes(currentPath)) {
      window.location.href = `/#${targetId}`;
    } else {
      // Scroll to the section on the current page
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 50,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            href="#"
            className="navbar-toggle collapsed language-button"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="img/language-icon.png"
              alt="Change Language"
            />{" "}
          </a>
          <ul className="dropdown-menu language-menu">
            <li>
              <a
                href="#en"
                onClick={() => handleLanguageChange("EN")}
                className={selectedLanguage === "EN" ? "active" : ""}
              >
                English
              </a>
            </li>
            <li>
              <a
                href="#cn"
                onClick={() => handleLanguageChange("中")}
                className={selectedLanguage === "中" ? "active" : ""}
              >
                简体中文
              </a>
            </li>
          </ul>
          <a className="navbar-brand page-scroll" href="/">
            <img src="img/boost-traffics-logo.png" alt="Boost Traffics Logo"></img>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#websiteTraffic"
                className="page-scroll"
                onClick={scrollToTab}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#pricingTable"
                className="page-scroll"
                onClick={scrollToTab}
              >
                Pricing
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="page-scroll"
                onClick={scrollToTab}
              >
                Contact
              </a>
            </li>
            {/* Add a vertical border here */}
            <li className="divider"></li>

            {/* Add Language Dropdown */}
            <li className="dropdown language-button-desktop">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedLanguage} <span className="caret"></span>
              </a>
              <ul className="dropdown-menu language-menu">
                <li>
                  <a
                    href="#en"
                    onClick={() => handleLanguageChange("EN")}
                  >
                    English
                  </a>
                </li>
                <li>
                  <a
                    href="#cn"
                    onClick={() => handleLanguageChange("中")}
                  >
                    简体中文
                  </a>
                </li>
              </ul>
            </li>
            {/* Add Sign Up Button */}
            <li>
              <a
                href={process.env.REACT_APP_CONTACT_LINK}
                target="_blank"
                className="btn btn-primary navbar-btn signin-btn"
                aria-label="Sign in for free"
              >
                Sign In for Free
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
