import React, { useEffect, useState } from "react";
import "./cookie-consent.css"; 

const CookieConsent = () => {
    const [isAccepted, setIsAccepted] = useState(null);

    useEffect(() => {
        const consent = localStorage.getItem("cookieConsent");
        if (consent) {
            setIsAccepted(consent === "accepted");
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setIsAccepted(true);
    };

    const handleDeny = () => {
        localStorage.setItem("cookieConsent", "denied");
        setIsAccepted(false);
    };

    if (isAccepted !== null) return null;

    return (
        <div className="cookie-popup">
            <div className="cookie-popup-content">
                <p>
                    We use cookies to improve your experience on our site. By clicking "Accept All," you agree to our <a href="/policies" className="policy-link">Policies</a>, including Privacy Policy, Terms and Conditions, and Refunds & Cancellations.
                </p>
                <div className="cookie-buttons">
                    <button onClick={handleAccept} className="btn accept-btn">Accept All</button>
                    <button onClick={handleDeny} className="btn deny-btn">Deny</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
