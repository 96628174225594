import React from "react";

export const TrafficComparison = (props) => {
    return (
        <div id="trafficComparison">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="title-left">
                            <h2>Without Boost Traffics</h2>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="title-right">
                            <h2>With Boost Traffics
                                <img src="img/boost-logo.png"
                                    alt="Boost Traffics Logo" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="trafficComparison-user">
                            <div className="data-left">
                                {props.data
                                    ? props.data.map((d, i) => (
                                        <React.Fragment key={i}>
                                            <p><span>{d.feature} : </span>{d["data-left"]}</p>
                                        </React.Fragment>
                                    ))
                                    : "loading"}
                                <img src="img/user-without-traffic.gif" alt="User activity in the last 30 minutes without Boost Traffic service" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="trafficComparison-user">
                            <div className="data-right">
                                {props.data
                                    ? props.data.map((d, i) => (
                                        <React.Fragment key={i}>
                                            <p><span>{d.feature} : </span>{d["data-right"]}</p>
                                        </React.Fragment>
                                    ))
                                    : "loading"}
                                <img src="img/user-with-traffic.gif" alt="User activity in the last 30 minutes with Boost Traffic service"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};