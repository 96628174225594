import React from "react";
import "./button-group.css";
import { PAGE_PATHS } from "../constant/paths"; 

export const ButtonGroup = ({ activeButton, onButtonClick }) => {
    const buttons = [
        { id: "privacy", label: "PRIVACY POLICY" },
        { id: "terms", label: "TERMS AND CONDITIONS" },
        { id: "refund", label: "REFUNDS & CANCELLATIONS" },
    ];

    const handleNavigation = (buttonId) => {
        switch (buttonId) {
            case "privacy":
                window.location.href = PAGE_PATHS.PRIVACY_POLICY;; 
                break;
            case "terms":
                window.location.href = PAGE_PATHS.TERMS; 
                break;
            case "refund":
                window.location.href = PAGE_PATHS.REFUND_CANCELLATION; 
                break;
            default:
                break;
        }
        onButtonClick(buttonId); 
    };

    return (
        <div id="buttonGroup">
            <div className="container">
                <div className="row">
                    <div className="button-group">
                        {buttons.map((button) => (
                            <button
                                key={button.id}
                                className={`button ${activeButton === button.id ? "active" : ""}`}
                                onClick={() => handleNavigation(button.id)}
                            >
                                {button.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
